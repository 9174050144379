import { useEffect } from 'react';

function TelegramWebApp() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return null; // This component doesn't render anything itself
}

export default TelegramWebApp;
