/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { domain } from './config';

const OrderHistory = () => {
    const [orders, setOrders] = useState([]);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [showAlert, setShowAlert] = useState(false);
    const [user, setUser] = useState({});
    const [translations, setTranslations] = useState({});
    const navigate = useNavigate();

    // Функция для получения переводов
    const fetchTranslations = async (language, initData) => {
        const keys = [
            "name_label",
            "start_time_label",
            "status_label",
            "actions_label",
            "create_order_button",
            "referral_system_button",
            "order_deleted",
            "order_in_progress",
            "order_scheduled",
            "fetch_orders_failed",
            "fetch_error",
            "delete_success",
            "delete_failed",
            "deposit_button",
            "balance_label"
        ];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData,
                },
                body: JSON.stringify({ keys, language }), // Указываем язык
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const initTg = async () => {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                clearTimeout(CheckTgAviable);
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': initData
                        }
                    });

                    if (!response.ok) {
                        // tgData.close();
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    setUser(data.user)
                    await fetchTranslations(data.user.language_code, initData);
                    fetchOrders(); // Теперь вызываем fetchOrders после получения переводов
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                CheckTgAviable = setTimeout(initTg, 500);
            }
        };

        let CheckTgAviable = setTimeout(initTg, 500);

        return () => clearTimeout(CheckTgAviable);
    }, []);

    const fetchOrders = async () => {
        try {
            const tgData = window.Telegram.WebApp;
            const initData = tgData.initData;

            const response = await fetch(`${domain}/my-orders`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': initData,
                    'ngrok-skip-browser-warning': "asd"
                },
            });

            const result = await response.json();

            if (response.ok) {
                setOrders(result.Orders);
            } else {
                setAlert({ message: result.detail || translations.fetch_orders_failed, type: 'error' });
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            }
        } catch (error) {
            console.log(error);
            setAlert({ message: translations.fetch_error, type: 'error' });
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    const handleDelete = async (taskId) => {
        try {
            const tgData = window.Telegram.WebApp;
            const initData = tgData.initData;

            const response = await fetch(`${domain}/delete-order/${taskId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': initData,
                    'ngrok-skip-browser-warning': "asd"
                },
            });

            if (response.ok) {
                const updatedOrders = { ...orders };
                delete updatedOrders[taskId];
                setOrders(updatedOrders);
                setAlert({ message: translations.delete_success, type: 'success' });
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            } else {
                setAlert({ message: translations.delete_failed, type: 'error' });
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            }
        } catch (error) {
            console.log(error);
            setAlert({ message: translations.fetch_error, type: 'error' });
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`; 
    };

    const statuses = {
        'scheduled': translations.order_scheduled,
        "deleted": translations.order_deleted,
        'active': translations.order_in_progress
    };

    return (
        
        <div className="order-history-container">
            <button className="create-order-btn" onClick={() => navigate('/new-order')}>{translations.create_order_button}</button>
            <button className="create-order-btn" style={{ background: "red" }} onClick={() => navigate('/referals')}>{translations.referral_system_button}</button>
            <button className="create-order-btn" style={{ background: "green" }} onClick={() => navigate('/deposit')}>{translations.deposit_button}</button>
            <button className="create-order-btn" style={{ background: "green" }} onClick={() => navigate('/deposit')}>{translations.balance_label}<br></br>$ {user.balance} </button>

            {showAlert && (
                <div className={`alert ${alert.type}`} dangerouslySetInnerHTML={{ __html: alert.message }} />
            )}

            <table className="order-table">
                <thead>
                    <tr>
                        <th>{translations.name_label}</th>
                        <th>{translations.start_time_label}</th>
                        <th>{translations.status_label}</th>
                        <th>{translations.actions_label}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(orders).map(([taskId, order]) => (
                        <tr key={taskId}>
                            <td>{order.streamer_id}</td>
                            <td>{formatDate(order.start_time)}</td>
                            <td>{statuses[order.status]}</td>
                            <td>
                                {order.status === 'deleted' ? (
                                    <button className="delete-btn" disabled>{translations.order_deleted}</button>
                                ) : (
                                    <button className="delete-btn" onClick={() => handleDelete(taskId)}>🗑</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrderHistory;
