import React, { useState } from 'react';

const AmountInput = ({ currency, onChange }) => {
    const [amount, setAmount] = useState("");

    const handleChange = (e) => {
        let newValue = parseFloat(e.target.value);

        if (newValue < e.target.min) {
            newValue = e.target.min;
        }

        setAmount(newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <input
            type="number"
            placeholder="0"
            value={amount}
            onChange={handleChange}
            className="amount-input"
            min="1"
        />
    );
};

export default AmountInput;