import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MobileForm from './MobileForm.js';
import OrderHistory from './OrderHistory.js';
import Profile from './Profile.js';
import Referals from './Referals.js';
import Deposit from './Deposit.js';
import './App.css';
import TelegramWebApp from './TelegramWebApp'; // Import the component

function App() {
  return (
    <Router>
      <TelegramWebApp /> 
      <Routes>
        <Route path="/new-order" element={<MobileForm />} />
        <Route path="/history" element={<OrderHistory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/referals" element={<Referals />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="*" element={<OrderHistory />} />
      </Routes>
    </Router>
  );
}

export default App;
