import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { domain } from './config';

const ReferralPage = () => {
    const [alert, setAlert] = useState(''); // Для уведомлений
    const [referralLink, setReferralLink] = useState("");
    const [invitedCount, setInvitedCount] = useState("");
    const [referralPercent, setReferralPercent] = useState(10);
    const [showAlert, setShowAlert] = useState(false); // Для управления отображением
    const [translations, setTranslations] = useState({});
    const navigate = useNavigate();

    // Функция для получения переводов
    const fetchTranslations = async (language, initData) => {
        const keys = [
            "referral_link_label",
            "copy_link_button",
            "invited_users_label",
            "referral_percent_label",
            "profile_button",
            "history_button"
        ];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData,
                },
                body: JSON.stringify({ keys, language }), // Указываем язык
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            setAlert('Ссылка скопирована в буфер обмена!');
            setTimeout(() => setAlert(''), 3000); // Уведомление на 3 секунды
        });
    };

    useEffect(() => {
        const initTg = async () => {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': initData,
                        },
                    });

                    const result = await response.json();

                    if (response.ok) {
                        setReferralLink(result.ref_link);
                        setInvitedCount(result.referral_count);
                        setReferralPercent(10);
                        await fetchTranslations(result.user.language_code, initData); // Получаем переводы
                    } else {
                        setAlert({ message: result.detail || 'Failed to fetch orders', type: 'error' });
                        setShowAlert(true);
                        setTimeout(() => setShowAlert(false), 3000);
                    }
                } catch (error) {
                    console.log(error);
                    setAlert({ message: 'Error fetching orders', type: 'error' });
                    setShowAlert(true);
                    setTimeout(() => setShowAlert(false), 3000);
                }
            } else {
                setTimeout(initTg, 500);
            }
        };

        let CheckTgAviable = setTimeout(initTg, 500); // Начальная проверка

        // Убираем таймаут при размонтировании компонента
        return () => clearTimeout(CheckTgAviable);
    }, []);

    return (
        <div className="referal-container">
            <h2>{translations.referral_link_label}</h2>

            {showAlert && (
                <div className={`alert ${alert.type}`} dangerouslySetInnerHTML={{ __html: alert.message }} />
            )}

            <a href={referralLink} className="referral-link" target="_blank" rel="noopener noreferrer">
                {referralLink}
            </a>

            <button className="copy-btn" onClick={copyToClipboard}>
                {translations.copy_link_button}
            </button>

            {alert && <div className="alert success">{alert}</div>}

            <div className="stats">
                <div className="stat-item">
                    <h3>{invitedCount}</h3>
                    <p>{translations.invited_users_label}</p>
                </div>
                <div className="stat-item">
                    <h3>{referralPercent}%</h3>
                    <p>{translations.referral_percent_label}</p>
                </div>
            </div>

            <button className="copy-btn" style={{ "background": "green" }} onClick={() => navigate("/profile")}>
                {translations.profile_button}
            </button><br />
            <button className="copy-btn" onClick={() => navigate("/history")}>
                {translations.history_button}
            </button>
        </div>
    );
};

export default ReferralPage;
