import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Для редиректа
import { domain } from "./config";

const MobileForm = () => {
    const [username, setUsername] = useState("");
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [duration, setDuration] = useState("");
    const [alert, setAlert] = useState({ message: '', type: '' }); // Для уведомлений
    const [showAlert, setShowAlert] = useState(false); // Для управления отображением
    const [translations, setTranslations] = useState({}); // Для хранения переводов
    const navigate = useNavigate(); // Для редиректа

    // Функция для получения переводов
    const fetchTranslations = async (language, initData) => {
        const keys = [
            "username_label",
            "date_label",
            "start_label",
            "duration_label",
            "submit_button",
            "username_placeholder",
            "duration_placeholder",
            "history_button",
            "profile_button",
            "buy_description",
            "error_future_time"
        ];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData,
                },
                body: JSON.stringify({ keys, language }), // Указываем язык
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Приведение имени пользователя к нижнему регистру
        const formattedUsername = username.toLowerCase();

        // Текущая дата и время
        const now = new Date();

        // Время, выбранное пользователем (соединение даты и времени)
        const userSelectedTime = new Date(`${date}T${startTime}:00`);

        // Проверяем, чтобы пользователь указал будущее время
        if (userSelectedTime <= now) {
            setAlert({ message: translations.error_future_time, type: "error" });
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            return;
        }

        // Вычисление задержки в секундах до указанного времени
        const delayInSeconds = Math.floor((userSelectedTime - now) / 1000);

        const requestData = {
            streamer_id: formattedUsername, // никнейм в нижнем регистре
            delay: delayInSeconds, // задержка в секундах до указанного времени
            work_time: duration // продолжительность работы в минутах
        };

        try {
            const tgData = window.Telegram.WebApp;
            const initData = tgData.initData;

            const response = await fetch(`${domain}/new-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData
                },
                body: JSON.stringify(requestData),
            });

            const result = await response.json();

            if (response.ok) {
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    navigate('/history'); // Редирект на страницу history
                }, 3000); // Убираем алерт через 3 секунды и делаем редирект
            } else {
                setAlert({ message: result.detail || "Failed to create order", type: "error" });
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000); // Убираем алерт через 3 секунды
            }
        } catch (error) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    useEffect(() => {
        const initTg = async () => {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': initData,
                            'ngrok-skip-browser-warning': "asd"
                        }
                    });

                    if (!response.ok) {
                        tgData.close();
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();

                    await fetchTranslations(data.user.language_code, initData);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }

                setDate(getTodayDate());
            } else {
                setTimeout(initTg, 500);
            }
        };

        initTg();
    }, []);

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return (
        <div className="form-container">

            <button className="create-order-btn" onClick={() => navigate('/history')}>{translations.history_button}</button>
            <button className="create-order-btn" style={{ "background": "green", "margin-left": "90px" }} onClick={() => navigate('/profile')}>{translations.profile_button}</button>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>{translations.username_label}</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder={translations.username_placeholder} // Updated placeholder
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{translations.date_label}</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        min={getTodayDate()}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{translations.start_label}</label>
                    <input
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{translations.duration_label}</label>
                    <input
                        type="number"
                        value={duration}
                        onChange={(e) => setDuration(parseInt(e.target.value, 10))}
                        placeholder={translations.duration_placeholder}
                        required
                    />
                </div>
                <button type="submit" className="submit-btn">
                    {translations.submit_button}
                </button>
                <br></br>
                <div dangerouslySetInnerHTML={{ __html: translations.buy_description }} />
            </form>

            {showAlert && (
                <div className={`alert ${alert.type}`} dangerouslySetInnerHTML={{ __html: alert.message }} />
            )}

        </div>
    );
};

export default MobileForm;
